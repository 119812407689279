import { Dropdown as BSDropdown } from "react-bootstrap";
import "./dropdown.scss";
import { ChevronDown } from "lucide-react";
import React, { useEffect } from "react";

const Dropdown = ({ children, className = "", arrowIcon = null, options = [], onSelect = null, selected = "", ...props }) => {
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === selected);
    setValue(selectedOption);
  }, [selected, options, value]);

  if (!options.length) throw new Error("Dropdown: options is required");
  return (
    <BSDropdown className="ml-dropdown" {...props}>
      <BSDropdown.Toggle className={`button outline ${className} ${value?.className}`}>
        {["object", "function"].includes(typeof value?.icon) ? <value.icon /> : value?.icon}
        {value?.label || "Select"}
        {!arrowIcon && <ChevronDown />}
      </BSDropdown.Toggle>

      <BSDropdown.Menu className="ml-dropdown-menu">
        {options.map((option, index) => (
          <BSDropdown.Item key={index} onClick={() => onSelect(option.value)}>
            {["object", "function"].includes(typeof option.icon) ? <option.icon /> : option.icon}
            <span>{option.label}</span>
          </BSDropdown.Item>
        ))}
      </BSDropdown.Menu>
    </BSDropdown>
  );
};

export default Dropdown;
