import React, { useState } from "react";
import "./testimonials.scss";
import { IconsList } from "../icon";
import { Card } from "../card/Card";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { sanitize } from "dompurify";

const Testimonials = ({ testimonials = [] }) => {
  if (!testimonials?.length) {
    testimonials = [
      {
        quote: `“Best deliverability solution. <strong>Better deliverability, better sales performance in the end.</strong> I used many tools before to solve my warmup issues. <strong>This tool is finally giving great results.”`,
        name: "Jessy Grossi",
        title: "Growth & Ops Manager",
        avatar:
          "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667c1f5975f4db94c0257611_Jessy%20Grossi%20Growth%20%26%20Ops%20Manager.jpg",
        customer: "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667436a90d65477112d3c342_Capterra%20Img.png",
        company: "Capterra",
      },
      {
        quote: `“MailReach helps us build and maintain the sender reputation and deliverability of 40 mailboxes with multiple domains</strong>. This solution is a game-changer for us and I recommend it to everyone who wants to get the best results from their outreach campaigns. ”`,
        name: "Hadil Filali",
        title: "Co-Founder of Sparkline",
        avatar: "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667d4c040946c36239607303_Hadil%20Filali.jpg",
        customer: "",
        company: "",
      },
      {
        quote:
          "We've tested many solutions and MailReach is definitely the most efficient email deliverability tool. MailReach helps us raise and maintain the deliverability of 20 email accounts. A great deliverability booster.",
        name: "Antonio Cerneli",
        title: "CEO @ Peakflow",
        avatar:
          "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667c19f00eafb7d809301067_Antonio%20Cerneli%2C%20CEO%20%40%20Peakflow.webp",
        customer: "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/66743697fa19a7757def3a13_G2%20Img.png",
        company: "g2",
      },
      {
        quote: `“My emails were at 35% deliverability and were all blocked by Microsoft when I started with MailReach. They not only helped me get the emails unblocked, <strong>they got me to a 92% deliverability within a week or 2.</strong> That's insane. <strong>Made a world of difference for my email deliverability.”</strong>`,
        name: "Adam Van Duyne",
        title: "CEO @ Hailo Digital Agency",
        avatar:
          "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667c1817eb93480c580c830c_Adam%20Van%20Duyne%20Hailo%20Digital%20Agency.webp",
        customer: "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/667436bb7e38a32575129c75_SaaSHub%20Img.png",
        company: "SaasHub",
      },
      {
        quote: `“We’ve tested many solutions and <strong>MailReach is definitely the most efficient email deliverability tool. </strong>MailReach helps us raise and maintain the deliverability of 20 email accounts. <strong>A great deliverability booster.”</strong>`,
        name: "Jean-Yves Delmotte",
        title: "Co-Founder @ PaletteHQ",
        avatar: "https://cdn.prod.website-files.com/6336c0ecc4a07d5c2a6b36c2/6654dfecc1b75517f1beadd7_Jean-Yves%20Delmotte.jpg",
        customer: "",
        company: "",
      },
    ];
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  return (
    <Card className="ml-customer-stories-container">
      <div className="ml-customer-stories-header">
        <h3>Customer stories</h3>
        <div className="ml-customer-stories-logo">
          <img src={testimonials[currentSlide].customer} alt={testimonials[currentSlide].company} />
        </div>
      </div>
      <div className="carousel">
        <div className="slide" style={{ opacity: 1 }}>
          <div className="slide-inner">
            <div className="rating">
              {[...Array(5)].map((_, index) => (
                <span key={index} className="star">
                  <IconsList.Star />
                </span>
              ))}
            </div>
            <div className="quote" dangerouslySetInnerHTML={{ __html: sanitize(testimonials[currentSlide].quote) }}></div>
          </div>
          <div className="author">
            <img src={testimonials[currentSlide].avatar} alt={testimonials[currentSlide].name} className="ml-testimonials-avatar" />
            <div className="author-info">
              <h3 className="name">{testimonials[currentSlide].name}</h3>
              <p className="title">{testimonials[currentSlide].title}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-nav">
        <button onClick={prevSlide}>
          <ArrowLeftIcon />
        </button>
        <button onClick={nextSlide}>
          <ArrowRightIcon />
        </button>
      </div>
    </Card>
  );
};

export default Testimonials;
