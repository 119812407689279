import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import InappMessageService from "../../services/inappMessage.service";
import { sanitize } from "dompurify";

class InappMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false, showModal: false, title: "Loading..." };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showModal: nextProps.show, handleCloseModal: nextProps.handleCloseModal });
    if (nextProps.keyname) {
      InappMessageService.get(nextProps.keyname)
        .then((response) => {
          this.setState({ title: response.body.title, content: response.body.content });
        })
        .catch((error) => {
          this.setState({ error: true, title: "Oops, we have an error" });
        });
    }
  }

  render() {
    return (
      <Modal className="reputation-modal" show={this.state.showModal} onHide={this.state.handleCloseModal}>
        <Modal.Body>
          <h1 className="text-center">{this.state.title}</h1>
          {this.state.error && (
            <div className="text-center">
              <img src="/images/404.svg" />
              <p>Oops, we have an error. We will fix it soon, sorry</p>
            </div>
          )}
          <div className="txt" dangerouslySetInnerHTML={{ __html: sanitize(this.state.content) }}></div>
          <div className="footer text-center">
            <Button variant="secondary" onClick={this.state.handleCloseModal}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    errors: state.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default InappMessage;
