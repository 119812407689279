import "./badge.scss";

export const Badge = ({ children, className = "", variant = "default", color = "default", icon = null, ...props }) => {
  return (
    <div className={`ml-badge ${className} ${variant}`} {...props}>
      {icon ? ["object", "function"].includes(typeof icon) && <icon /> : null}
      {children}
    </div>
  );
};
