import { Tooltip } from "@material-ui/core";
import { Card, IconsList } from "../../../components/core";
import CircularProgress from "../../../components/core/chart-data/CircularProgress";
import "./score.scss";

export const ScoreInfo = ({ score = 0, max = 10, tooltip = "", description = "" }) => {
  return (
    <Card className="ml-score-info">
      <div
        className="ml-score-bg-mask"
        style={{
          backgroundImage: 'url("/images/matrix.svg")',
        }}
      />
      <div className="ml-score-header">
        <h3>Score</h3>
        <Tooltip title={tooltip} placement="bottom">
          <i>{<IconsList.InfoQuestionMark />}</i>
        </Tooltip>
      </div>
      <div className="ml-score-content">
        <CircularProgress value={score} maxValue={max} />
      </div>
      <p>{description}</p>
    </Card>
  );
};
