import { Icon } from "../icon";

import gmail from "../../../assets/img/svg/gmail.svg";
import outlook from "../../../assets/img/svg/outlook.svg";
import yahoo from "../../../assets/img/svg/yahoo.svg";
import custom from "../../../assets/img/svg/custom.svg";

const ClientsIcons = {
  gmail,
  outlook,
  yahoo,
  custom,
};

export const ClientIcon = ({ style = {}, provider = "none", ...props }) => {
  const ProviderIcon = ClientsIcons[provider];
  return (
    <Icon className="ml-email-icon-box" style={style} {...props}>
      {provider !== "none" ? <img src={ProviderIcon} alt={provider} width={64} height={64} /> : null}
    </Icon>
  );
};
