import React from "react";
import { Gift, X } from "lucide-react";
import "./banner.scss";
import Button from "../button/Button";
import { Icon } from "../icon";
import { sanitize } from "dompurify";
import Lottie from "react-lottie";

export const OfferBanner = ({
  children,
  variant = "",
  className = "",
  title = "Limited-time offer: Get Higher Deliverability for Less!",
  message = "Start improving your email outreach with 20% OFF for the first 30 days of MailReach B2B warming.",
  cta = "Claim 20% off now",
  icon = null,
  closable = true,
  href = "https://www.mailreach.co/email-warmup/?utm_medium=warmer_cta&utm_campaign=setup-tab",
  onBannerClose = null,
  ...props
}) => {
  const [showBanner, setShowBanner] = React.useState(true);
  const closeBanner = () => {
    setShowBanner(false);
    onBannerClose && onBannerClose();
  };

  return showBanner ? (
    <div className={`ml-offer-banner ${variant} ${className}`.trim()} {...props}>
      <div className="ml-offer-banner-container">
        <div className="ml-offer-banner-content">
          <Icon>
            <Gift />
          </Icon>
          <div>
            <div className="ml-offer-banner-title">{title}</div>
            <div className="ml-offer-banner-message">{message}</div>
          </div>
        </div>
        <div className="ml-offer-banner-cta">
          <Button variant="outline" className="light sm" as="a" href={href}>
            {cta}
          </Button>
        </div>
      </div>
      {closable && (
        <button className="ml-offer-banner-close" onClick={() => closeBanner()}>
          <X />
        </button>
      )}
    </div>
  ) : null;
};

export const CTABanner = ({ title = "", cta = "Start improving deliverability", href = "", className }) => {
  return (
    <div className={`ml-cta-banner ${className}`}>
      <p dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
      <Button variant="secondary" as="a" href={href}>
        {cta}
      </Button>
    </div>
  );
};

export const CTAWithImage = ({
  title = "",
  cta = "Start improving deliverability",
  image = "",
  href = "",
  lottie = null,
  children,
  className,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={`ml-cta-image-banner ${className}`}>
      <div className="ml-cta-banner-content">
        <h3 dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
        {children}
        <Button variant="secondary" as="a" href={href}>
          {cta}
        </Button>
      </div>

      <div className="ml-cta-banner-image">
        {lottie ? <Lottie options={defaultOptions} height={"100%"} width={"auto"} /> : <img src={image} alt={title} />}
      </div>
    </div>
  );
};

export const BannerWithAds = ({
  title = "",
  description = "",
  cta = "Start improving deliverability",
  href = "",
  className,
  variant = "default",
  iconName = "",
  children,
}) => {
  return (
    <div className={`ml-blue-banner ml-ads-banner ${className ?? ""} ${variant}`}>
      {iconName && <Icon name={iconName} />}
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      {children}
      {href && (
        <Button variant="secondary" as="a" href={href}>
          {cta}
        </Button>
      )}
    </div>
  );
};
