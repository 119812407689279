import { Card } from "../card/Card";
import "./stats.scss";

export const Stats = ({
  title = "Mailreach helps B2B companies get",
  stat1 = "Average Open Rates",
  stat1Value = "67%",
  stat2 = "Average Revenue Growth From Email",
  stat2Value = "19%",
}) => {
  return (
    <Card className="stats-container">
      <h3 className="title">{title}</h3>

      <div className="stat-blocks">
        <div className="stat-block">
          <span className="stat-label">{stat1}</span>
          <p className="stat-value blue">{stat1Value}</p>
        </div>

        <div className="stat-block">
          <span className="stat-label">{stat2}</span>
          <p className="stat-value green">{stat2Value}</p>
        </div>
      </div>
    </Card>
  );
};
