import { useEffect, useState } from "react";

const CircularProgress = ({ value = 10, maxValue = 10 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Start from 0 and animate to the target value
    setProgress(0);
    const timer = setTimeout(() => {
      setProgress(value);
    }, 0);
    return () => clearTimeout(timer);
  }, [value]);

  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const strokeWidth = 8;
  const normalizedProgress = (progress / maxValue) * 100;
  const strokeDashoffset = circumference - (normalizedProgress / 100) * circumference;

  const getRingColor = () => {
    if (value < 5) {
      return "#D83B22";
    } else if (value <= 7.5) {
      return "#DBA056";
    } else {
      return "#27B970";
    }
  };

  const containerStyle = {
    position: "relative",
    width: "160px",
    height: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const svgStyle = {
    transform: "rotate(-90deg)",
    width: "100%",
    height: "100%",
  };

  const circleBaseStyle = {
    fill: "none",
    strokeWidth,
  };

  const circleBackgroundStyle = {
    ...circleBaseStyle,
    stroke: "#ebebeb",
  };

  const circleProgressStyle = {
    ...circleBaseStyle,
    stroke: getRingColor(),
    strokeLinecap: "round",
    strokeDasharray: circumference,
    strokeDashoffset,
    transition: "stroke-dashoffset 1s ease-in-out",
  };

  const textContainerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  const valueStyle = {
    fontSize: "44px",
    fontWeight: "700",
    lineHeight: "1",
    color: getRingColor(),
    margin: 0,
  };

  const labelStyle = {
    fontSize: "16px",
    color: "#5c5c70",
    margin: "0.25rem 0 0 0",
  };

  return (
    <div style={containerStyle}>
      <svg style={svgStyle} viewBox="0 0 100 100">
        {/* Background circle */}
        <circle cx="50" cy="50" r={radius} style={circleBackgroundStyle} />
        {/* Progress circle */}
        <circle cx="50" cy="50" r={radius} style={circleProgressStyle} />
      </svg>
      <div style={textContainerStyle}>
        <p style={valueStyle}>{progress}</p>
        <p style={labelStyle}>out of {maxValue}</p>
      </div>
    </div>
  );
};

export default CircularProgress;
