import React from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import { AnalyzerInfo } from "../../pages/tests/modules/AnalyzerInfo";
import { Button, Card } from "../core";

//import InappMessageService from '../../services/inappMessage.service';

class WordsAnalyzer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      blacklists: [],
      blacklist_sending_ip: [],
      data: { spamwords: [], links_results: { details: [] } },
      spamwords: [],
      unsecuredLinks: [],
      securedLinks: [],
      spammy: false,
      numberImages: 0,
    };
    this.setData = this.setData.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    this.setData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setData(nextProps);
  }

  handleCloseModal() {
    this.setState({ showBrokenLinksListModal: false, showSpamwordsModal: false, showBlacklistModal: false });
  }

  copyText(value) {
    navigator.clipboard.writeText(value);
    toast.success("Link successfully copied 🙌");
  }

  setData(nextProps) {
    if (nextProps.contentSpamwordsResponse) {
      console.log(nextProps.contentSpamwordsResponse);
      this.setState({
        data: nextProps.contentSpamwordsResponse,
        spamwords: nextProps.contentSpamwordsResponse.spamwords || [],
        unsecuredLinks: nextProps.contentSpamwordsResponse.number_unsecured_links || 0,
        securedLinks: nextProps.contentSpamwordsResponse.number_secured_links || 0,
        numberImages: nextProps.contentSpamwordsResponse.number_images,
        spammy: nextProps.contentSpamwordsResponse.spammy,
      });
    }
  }

  render() {
    return (
      <div>
        {this.props.title !== false && <h2 className="title">Analyzer</h2>}

        {this.state.spamwords.length > 0 && this.state.data.type !== "template" && (
          <h3 className="title">
            <span className="nb">{this.state.spamwords.length}</span> spam words detected
          </h3>
        )}

        {this.state.spamwords.size > 0 && this.state.data.type !== "template" && (
          <ul className="spamlist">
            {this.state.spamwords.map((spamword) => {
              return <li>{spamword}</li>;
            })}
          </ul>
        )}

        <Card className="ml-analyzer-card">
          {this.state.data.type === "template" && (
            <>
              {this.state.spamwords.length > 0 && (
                <AnalyzerInfo
                  title={this.state.spamwords.length + " spam or risky word(s) detected. There can be more. To be replaced or removed."}
                  tooltip="Today, with machine learning, any word can be a spam word, sometimes words you wouldn't expect. MailReach can't identify all of them. Beware!"
                  isLoading={this.state.loading}
                  button="Details"
                  type="warning"
                  onAction={() => this.setState({ showSpamwordsModal: true })}
                />
              )}

              {this.state.spamwords.length === 0 && (
                <AnalyzerInfo
                  title="0 obvious known spam or risky word detected. But you could still have some."
                  tooltip="Today, with machine learning, any word can be a spam word, sometimes words you wouldn't expect. MailReach can't identify all of them. Beware!"
                />
              )}

              {this.props.currentTest.first_message_content && this.props.currentTest.first_message_content.subject.trim().length === 0 && (
                <AnalyzerInfo title="Your email doesn't have a subject, this can be considered spammy, add one!" type="warning" />
              )}

              {this.props.currentTest.blacklist_links.length === 0 && (
                <AnalyzerInfo title="Your email body doesn't contain any blacklisted domains." />
              )}

              {this.props.currentTest.blacklist_links.length > 0 && (
                <AnalyzerInfo
                  title={"Your email body contains" + this.props.currentTest.blacklist_links.length + " blacklisted domain(s)."}
                  isLoading={this.state.loading}
                  button="Details"
                  type="warning"
                  onAction={() => this.setState({ showBlacklistModal: true, blacklists: this.props.currentTest.blacklist_links })}
                />
              )}

              {this.state.unsecuredLinks + this.state.securedLinks === 1 && (
                <AnalyzerInfo
                  title={
                    "Your email contains " +
                    (this.state.unsecuredLinks + this.state.securedLinks) +
                    " link(s). The fewer links, the better for your deliverability."
                  }
                  isLoading={this.state.loading}
                  button="Details"
                  type="warning"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}

              {this.state.unsecuredLinks + this.state.securedLinks === 0 && (
                <AnalyzerInfo title={"Your email contains 0 links. The fewer links, the better for your deliverability."} />
              )}

              {this.state.unsecuredLinks + this.state.securedLinks === 2 && (
                <AnalyzerInfo
                  title={
                    "We have detected " +
                    (this.state.unsecuredLinks + this.state.securedLinks) +
                    " links. The fewer links, the better for your deliverability."
                  }
                  isLoading={this.state.loading}
                  button="Details"
                  type="warning"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}

              {this.state.unsecuredLinks + this.state.securedLinks > 2 && this.state.unsecuredLinks + this.state.securedLinks < 5 && (
                <AnalyzerInfo
                  title={`We have detected ${
                    this.state.unsecuredLinks + this.state.securedLinks
                  } links. The fewer links, the better. We recommend to reduce this number.`}
                  isLoading={this.state.loading}
                  button="Details"
                  type="error"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}
              {this.state.unsecuredLinks + this.state.securedLinks >= 5 && this.state.unsecuredLinks + this.state.securedLinks < 7 && (
                <AnalyzerInfo
                  title={`We have detected ${
                    this.state.unsecuredLinks + this.state.securedLinks
                  } links, this is a lot. The fewer links, the better. We recommend to reduce this number.`}
                  isLoading={this.state.loading}
                  button="Details"
                  type="error"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}
              {this.state.unsecuredLinks + this.state.securedLinks >= 7 && (
                <AnalyzerInfo
                  title={` We have detected ${
                    this.state.unsecuredLinks + this.state.securedLinks
                  } links, that's way too many. The fewer links, the better. Reduce this number.`}
                  isLoading={this.state.loading}
                  button="Details"
                  type="error"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}

              {this.state.unsecuredLinks > 0 && (
                <AnalyzerInfo
                  title={`You have ${this.state.unsecuredLinks} insecure link(s) in your email body. It can affect your deliverability.`}
                  button="Details"
                  type="error"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details.filter((item) => !item.secured),
                      modalLinksTitle: "Insecure links",
                    })
                  }
                />
              )}

              {this.state.data.number_shortened_links > 0 && (
                <AnalyzerInfo
                  title={`We have detected ${this.state.number_shortened_links} bit.ly link(s), this is considered very spammy. To be removed ASAP.`}
                  button="Details"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details,
                      modalLinksTitle: "Links in your email",
                    })
                  }
                />
              )}

              {this.state.data.links_results.broken_links > 0 && (
                <AnalyzerInfo
                  title={`Your email body contains ${this.state.data.links_results.broken_links} broken link(s).  It can have a very negative impact on your deliverability.`}
                  button="Details"
                  onAction={() =>
                    this.setState({
                      showBrokenLinksListModal: true,
                      modalLinks: this.state.data.links_results.details.filter((item) => item.broken),
                      modalLinksTitle: "Broken links",
                    })
                  }
                />
              )}

              {this.state.data.attachments && (
                <>
                  {this.state.data.attachments.length > 0 && (
                    <AnalyzerInfo title={`We have detected an attachment. Sending attachments is a very bad practice. Remove it!`} />
                  )}

                  {this.state.data.attachments.length === 0 && (
                    <AnalyzerInfo title={` Your email doesn't contain any attachment. All good.`} />
                  )}
                </>
              )}

              {this.state.data.number_html_tags >= 100 && (
                <AnalyzerInfo
                  type="warning"
                  title={`Your HTML body is too heavy / complex. The lighter and simpler the HTML, the better.`}
                />
              )}

              {this.state.data.number_images > 4 && (
                <AnalyzerInfo
                  type="warning"
                  title={`Your email body contains image content. The lower the proportion of image content, the better.`}
                />
              )}

              {this.state.data.images_text_ratio !== undefined && (
                <>
                  {this.state.data.images_text_ratio <= 20 && (
                    <AnalyzerInfo
                      title={`Your text to image ratio is ${(100 - this.state.data.images_text_ratio).toFixed(1)}% text and ${
                        this.state.data.images_text_ratio
                      }% image. That's great.`}
                    />
                  )}
                  {this.state.data.images_text_ratio > 20 && this.state.data.images_text_ratio <= 40 && (
                    <AnalyzerInfo
                      title={`Your text to image ratio is ${(100 - this.state.data.images_text_ratio).toFixed(1)}%
                      text and ${this.state.data.images_text_ratio}% image. The less image content, the better for your deliverability.`}
                    />
                  )}

                  {this.state.data.images_text_ratio > 40 && (
                    <AnalyzerInfo
                      title={`Your text to image ratio is ${(100 - this.state.data.images_text_ratio).toFixed(1)}% text and ${
                        this.state.data.images_text_ratio
                      }% image. The less image content, the better for your deliverability.`}
                    />
                  )}
                </>
              )}

              {this.state.data.images.filter((img) => img.pixel).length === 0 && (
                <AnalyzerInfo title={`We don't have detected any tracking pixel. The less tracking, the better for your deliverability.`} />
              )}

              {this.state.data.images.filter((img) => img.pixel).length > 0 && (
                <AnalyzerInfo
                  type="warning"
                  title={`We have detected ${this.state.data.images.filter((img) => img.pixel).length} tracking
                  pixel(s). The less tracking, the better. Make sure to 
                  <a
                    target="_blank"
                    href="https://help.mailreach.co/en/article/whats-a-custom-tracking-domain-and-why-you-absolutely-need-one-imt0me/"
                  >
                    use a custom tracking domain
                  </a>
                  .`}
                />
              )}

              {this.state.unsecuredLinks === 0 && <AnalyzerInfo title={`You don't have any insecure links in your email body.`} />}

              {this.state.data.links_results.broken_links === 0 && (
                <AnalyzerInfo title={`You don't have any broken links in your email body.`} />
              )}

              {this.state.data.number_html_tags < 100 && (
                <AnalyzerInfo title={`The HTML code of your email is light and not too heavy, that's fine.`} />
              )}
            </>
          )}
        </Card>

        {this.state.data.type === "signature" &&
          (this.state.unsecuredLinks > 0 || this.state.securedLinks > 0 || this.state.signatureImages > 0) && (
            <h3 className="title">
              <span className="nb">{this.state.unsecuredLinks + this.state.securedLinks + this.state.numberImages}</span> problem(s)
            </h3>
          )}

        <div className="spamlist">
          {this.state.data.type == "signature" && (this.state.unsecuredLinks > 0 || this.state.securedLinks > 0) && (
            <div className="violated-rule">
              <span className="tag hight">High</span>{" "}
              <p>We do not allow links in the signature to maximize the warm up effect. Please remove it 🙏</p>
            </div>
          )}

          {this.state.signatureImages > 0 && (
            <div className="violated-rule">
              <span className="tag hight">High</span>{" "}
              <p>We do not allow images in the signature to maximize the warm up effect. Please remove it 🙏</p>
            </div>
          )}

          {(this.state.unsecuredLinks > 0 || this.state.securedLinks > 0 || this.state.numberImages > 0) && this.props.signature && (
            <div className="violated-rule info">
              <i class="fas fa-info-circle"></i> We intentionally prevent to save the signature with links and/or images inside as it raises
              the risk to land in spam. We're doing this for your own good 😇
            </div>
          )}

          {!this.state.spammy && this.props.signature && (
            <div className="empty text-center">
              <img src="/images/analysis.svg" />
              <p>We will analyse your signature before save it.</p>
            </div>
          )}

          {/*(!this.state.spammy && !this.props.signature)  &&
						<div className="empty text-center">
							<img src="/images/completed.svg" />
							<p>Your template looks good.</p>
						</div>
					*/}
          <div className="clearfix"></div>
        </div>

        <Modal
          className="reputation-modal blacklists-modal links-analysis-modal"
          show={this.state.showBrokenLinksListModal}
          onHide={this.handleCloseModal}
        >
          <Modal.Body>
            <>
              <h1 className="text-center">{this.state.modalLinksTitle}</h1>
              <div className="txt text-center">
                {this.state.data.links_results.details.length > 0 && (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Url</th>
                        <th className="th-text-center text-center" scope="col">
                          Response code
                        </th>
                      </tr>
                    </thead>
                    {this.state.modalLinks && (
                      <tbody>
                        {this.state.modalLinks.map((link, index) => {
                          return (
                            <tr>
                              <td className="text-left" onClick={() => this.copyText(link.url)}>
                                <Tooltip title={link.url} placement="bottom">
                                  <div className="pull-left">
                                    {link.url.slice(0, 50)}
                                    {link.url.length > 50 && <>...</>}
                                  </div>
                                </Tooltip>
                                {link.unsubscribe && (
                                  <span className="help-bubble pull-right">
                                    <Tooltip
                                      title="⚠️ MailReach detects this link as an unsubscribe link. We 100% recommend to have one but keep in mind that it's still a link and as such can make you land in spam if the domain of that link is not liked by spam filters. To test that, run a spam test with it VS a spam test without it."
                                      placement="bottom"
                                    >
                                      <div>
                                        <i class="fas fa-info-circle"></i>
                                      </div>
                                    </Tooltip>
                                  </span>
                                )}
                              </td>
                              <td>
                                {link.code == "500" && (
                                  <div className="red">
                                    <i class="far fa-times-circle"></i> {link.code}
                                  </div>
                                )}

                                {link.code != "200" && link.code != "500" && (
                                  <div className="black">
                                    <i class="far fa-dot-circle"></i> {link.code}
                                  </div>
                                )}
                                {link.code == "200" && (
                                  <div className="green">
                                    <i class="far fa-check-circle"></i> {link.code}
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </>
            <div className="footer text-center">
              <Button variant="outline" onClick={this.handleCloseModal}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="reputation-modal blacklists-modal links-analysis-modal"
          show={this.state.showSpamwordsModal}
          onHide={this.handleCloseModal}
        >
          <Modal.Body>
            <>
              <h1 className="text-center">Spamwords analysis</h1>
              <div className="txt text-center">
                {this.state.data.spamwords.length > 0 && (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Spamword</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.spamwords.map((word, index) => {
                        return (
                          <tr>
                            <td className="text-left">{word}</td>
                            <td>
                              <div className="red">
                                <i class="far fa-times-circle"></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </>
            <div className="footer text-center">
              <Button variant="outline" onClick={this.handleCloseModal}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="reputation-modal blacklists-modal" show={this.state.showBlacklistModal} onHide={this.handleCloseModal}>
          <Modal.Body>
            <>
              <h1 className="text-center">Blacklists check</h1>
              <div className="txt text-center">
                <div className="alert status warning card bounce">
                  <span>Read our article about being blacklisted</span>
                  <a
                    href="https://help.mailreach.co/en/article/my-domain-is-blacklisted-is-it-serious-and-what-to-do-1qlp1f7/"
                    target="_blank"
                    target="_blank"
                    className="btn pull-right"
                  >
                    Read
                  </a>
                </div>

                {this.state.blacklists.length == 0 && (
                  <>
                    <img src="/images/taken.svg" />
                    <p>
                      We don't have blacklist data for this domain. If you use a freemail (Like @gmail.com or @outlook.com) we don't run
                      blacklists check. If not, we should run a check on the next 24 hours 😉
                    </p>
                  </>
                )}

                {this.state.blacklists.length > 0 && (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">List name</th>
                        <th scope="col">Domain/IP</th>
                        <th scope="col">Listed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.blacklists.map((blacklist, index) => {
                        return (
                          <tr>
                            <td className="text-left">
                              {blacklist.blacklist == "fresh.spameatingmonkey.net" && (
                                <Tooltip
                                  title="Don't panic, that's normal. This blacklist lists the 'fresh' domains that are only 5 days old or younger. It should go away once you pass the 5 days period."
                                  placement="bottom"
                                >
                                  <div>
                                    {blacklist.blacklist} &nbsp;
                                    <i class="fas fa-info-circle"></i>
                                  </div>
                                </Tooltip>
                              )}
                              {blacklist.blacklist != "fresh.spameatingmonkey.net" && <>{blacklist.blacklist}</>}
                            </td>

                            <td className="text-left">{blacklist.checked_value}</td>
                            <td>
                              {blacklist.listed && (
                                <div className="red">
                                  <i class="far fa-times-circle"></i> Yes
                                </div>
                              )}
                              {!blacklist.listed && (
                                <div className="green">
                                  <i class="far fa-check-circle"></i> No
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </>
            <div className="footer text-center">
              <Button variant="outline" onClick={this.handleCloseModal}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    errors: state.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default WordsAnalyzer;
