import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import Tooltip from "@material-ui/core/Tooltip";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import { CreatedInDayAgo } from "../../helpers/time";
import "react-toastify/dist/ReactToastify.css";
import spamFilterLottie from "../../assets/lotttie/spam-filter.json";

import TestService from "../../services/test.service";
//import ConversationService from '../../../../services/conversation.service';
//import TemplateService from '../../../../services/template.service';
// import AccountService from "../../services/account.service";
import DomainBlacklistService from "../../services/domainBlacklist.service";

import WordsAnalyzer from "../../components/words-analyzer/words-analyzer.js";
import CheckupDomain from "../../components/checkup-domain/checkup-domain.js";

import "./test.scss";
import "./styles/test.ui.scss";
import "../../assets/fonts/fonts.css";
import "../../assets/styles/main.scss";
import { Badge, Button, Card, Dropdown, IconsList } from "../../components/core";
import { ArrowRight, BriefcaseBusiness, ChartLine, CircleUser, Globe, MailIcon, MailQuestionIcon } from "lucide-react";
import Tabs, { TabDot } from "../../components/core/tabs/Tabs.js";
import { BannerWithAds, CTABanner, CTAWithImage } from "../../components/core/banners/Banner.js";
import { EmailInfo } from "../../components/core/email/Info.js";
import { Stats } from "../../components/core/stats/Stats.js";
import Testimonials from "../../components/core/testimonials/Testimonials.js";
import { sanitize } from "dompurify";
import { ScoreInfo } from "./modules/ScoreInfo.js";
import { CirclePattern } from "../../components/core/patterns";
import moment from "moment";

const testTitles = {
  test: "Results",
  content: "Content Analysis",
  technical: "Setup Analysis",
};

let adsRd = [2, 2, 2, 2];
adsRd = adsRd[Math.floor(Math.random() * adsRd.length)];

class AppTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   errors: [],
      spamLogs: [],
      template: {},
      openShowHeadersModal: false,
      headersToShow: [],
      blockedNextTest: false,
      loading: true,
      showPopup: false,
      currentPage: "test",
      currentTabValue: "deliverability-tab",
      errors: { setup: 0 },
      currentTest: { account: {} },
      currentAccount: {},
      test_type: "all",
      messageContentPreview: "content_html",
    };
    this.reloadTests = this.reloadTests.bind(this);
    this.checkBlockedNextTest = this.checkBlockedNextTest.bind(this);
    this.calculeNumberSetupAlert = this.calculeNumberSetupAlert.bind(this);
    this.loadPopup = this.loadPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.openHeadersModal = this.openHeadersModal.bind(this);
  }

  componentWillMount() {
    this.setState({ testId: this.props.match.params.test_id });
    if (this.props.match.params.test_id) {
      TestService.get(this.props.match.params.test_id)
        .then((response) => {
          let currentTest = response.body;

          currentTest.results = currentTest.results.sort(function (a, b) {
            if (a.provider < b.provider) {
              return -1;
            }
            if (a.provider > b.provider) {
              return 1;
            }
            return 0;
          });

          this.setState({ currentTest: currentTest, loading: false });
          this.filterAccounts(response.body.test_type);

          this.calculeNumberSetupAlert(currentTest);

          setTimeout(() => {
            this.loadPopup();
          }, 3000);

          if (!response.body || !response.body.completed) {
            // Reload test every 8s if not completed
            setInterval(() => this.reloadTests(), 2000);
          }
          this.checkBlockedNextTest();
        })
        .catch(() => {
          window.location.href = "https://www.mailreach.co/upgrade/";
        });
    }
  }

  reloadTests() {
    this.setState({ reloadTests: true });
    TestService.get(this.props.match.params.test_id)
      .then((response) => {
        // Redirect to upgrade page if test is blocked
        if (response.body.blocked) {
          window.location.href = "https://www.mailreach.co/upgrade/";
          return;
        }
        this.setState({ currentTest: response.body, reloadTests: false });
        this.filterAccounts(response.body.test_type);
        this.checkBlockedNextTest();
        this.loadPopup();
      })
      .catch((error) => {
        toast.error("Oops, we have an issue, try again");
      });
  }

  checkBlockedNextTest() {
    TestService.checkBlocked(this.props.match.params.test_id).then((response) => {
      this.setState({ blockedNextTest: response.body.blocked });
    });
  }

  //
  calculeNumberSetupAlert(currentTest) {
    let errors = 0;
    // Calculate Setup error
    if (currentTest.domain) {
      if (!currentTest.domain.spf_valid) {
        errors = errors + 1;
      }
      if (!currentTest.domain.dkim_valid) {
        errors = errors + 1;
      }

      if (currentTest.domain.creation_date) {
        if (CreatedInDayAgo(currentTest.domain.creation_date) < 32) {
          errors = errors + 1;
        }
      }

      DomainBlacklistService.get(currentTest.public_id).then((response) => {
        let blacklistsFounds = response.body.filter((item) => item.listed).length;
        if (blacklistsFounds > 0) {
          errors = errors + 1;
        }
        this.setState({ error: { setup: errors } });
      });
    }
  }

  getScoreClass(score) {
    if (score < 5) {
      return "bad";
    } else if (score < 7.5) {
      return "average";
    } else {
      return "good";
    }
  }

  filterAccounts(type) {
    let freeMails = ["gmail.com", "outlook.com", "outlook.fr", "hotmail.com", "yahoo.com"];
    let currentTest = this.state.currentTest;
    let spamLogs = currentTest.results;
    if (type === "btob") {
      spamLogs.map((spamLog) => (spamLog.hidden = freeMails.includes(spamLog.email.split("@")[1])));
    }
    if (type === "btoc") {
      spamLogs.map((spamLog) => (spamLog.hidden = !freeMails.includes(spamLog.email.split("@")[1])));
    }
    if (type === "all") {
      spamLogs.map((spamLog) => (spamLog.hidden = false));
    }

    // Re-filter only btob and btoc accounts
    if (["btob", "btoc"].includes(type)) {
      spamLogs = spamLogs.sort(function (a, b) {
        if (a.provider < b.provider) {
          return -1;
        }
        if (a.provider > b.provider) {
          return 1;
        }
        return 0;
      });
    }

    currentTest.results = spamLogs;
    this.setState({ currentTest, spamTestInboxFilter: type });
  }

  setTestType(type) {
    // Set test type
    TestService.setType(this.props.match.params.test_id, type)
      .then((response) => {
        // Reload test and show restults
        this.reloadTests();
        //toast.success('Test type saved !');
      })
      .catch((error) => {
        toast.error("Oops, we have an issue, try again");
      });
  }

  getInboxTypeLabel(email) {
    let freeMails = ["gmail.com", "outlook.com", "outlook.fr", "hotmail.com", "yahoo.com"];
    let domain = email.split("@")[1];
    if (freeMails.includes(domain)) {
      return <Badge variant="personal">Personal</Badge>;
    } else {
      return <Badge variant="pro">Professional</Badge>;
    }
  }

  getInboxType(email) {
    let freeMails = ["gmail.com", "outlook.com", "outlook.fr", "hotmail.com", "yahoo.com"];
    let domain = email.split("@")[1];
    if (freeMails.includes(domain)) {
      return "perso";
    } else {
      return "pro";
    }
  }

  copyText(value) {
    navigator.clipboard.writeText(value);
    toast.success("Share link successfully copied 🙌");
  }

  loadPopup() {
    if (!localStorage.getItem("hide_popup_wrm")) {
      if (
        this.state.currentTest.btob_score < 8 &&
        this.state.currentTest.test_type &&
        ["all", "btob"].includes(this.state.currentTest.test_type) &&
        this.state.currentTest.spam
      ) {
        if (this.state.currentTest.domain) {
          if (
            this.state.currentTest.domain.spf_valid &&
            this.state.currentTest.domain.dkim_valid &&
            ["gmail.com", "google.com", "outlook.com", "outlook.fr", "hotmail.com"].includes(this.state.currentTest.sending_provider)
          ) {
            if (this.state.showPopup === false) {
              this.setState({ showPopup: true });
              window.gtag("event", "spamtest", {
                event_category: "spamtest",
                event_label: "warmer_pop_up",
              });
            }
          }
        }
      }
    }
  }

  closePopup() {
    this.setState({ showPopup: false });
    localStorage.setItem("hide_popup_wrm", true);
  }

  openHeadersModal(log) {
    if (Array.isArray(log.raw_headers)) {
      this.setState({ openShowHeadersModal: true, headersToShow: log.raw_headers });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="app container text-center">
          <div className="loader">
            <Loader type="Oval" color="#b7b7b7" height="100" width="100" />
          </div>
        </div>
      );
    }

    if (this.state.currentTest) {
      if (!this.state.currentTest.first_message_content) {
        return (
          <div className="app container content-container ml-page-bg">
            <BannerWithAds
              variant="light"
              title="MailReach is waiting to receive your email"
              description=""
              href={""}
              cta="Back to dashboard"
              iconName="Spinner"
            >
              <p>It usually takes between 20 and 60 seconds to load your result. If nothing shows up after 60 seconds :</p>
              <p>
                1. Are you sure you added the custom code in your email?
                <br />
                2. Are you sure your email has been sent?
              </p>
            </BannerWithAds>
          </div>
        );
      }
    }

    return (
      <>
        <CirclePattern />
        <div className="app home container spamtest-results spamtest-results-page spam-results-modal template-home">
          <div className="test-page-header">
            <h1>Email Spam Test Report</h1>
            <Tabs
              options={[
                { value: "test", tabValue: "deliverability-tab", label: "Deliverability", icon: MailIcon },
                {
                  value: "content",
                  label: "Content Analysis",
                  tabValue: "content-analysis-tab",
                  icon: ChartLine,
                  suffix: this.state.currentTest.analysis_results && this.state.currentTest.analysis_results.error_count > 0 && TabDot,
                },
                {
                  value: "technical",
                  label: "Setup Analysis",
                  tabValue: "setup-analysis-tab",
                  icon: IconsList.Analysis,
                  suffix: this.state.errors && this.state.errors.setup > 0 && TabDot,
                },
              ]}
              selected={this.state.currentPage}
              onSelect={(value, opt) => this.setState({ currentPage: value, currentTabValue: opt.tabValue })}
            />
          </div>

          {!this.state.currentTest.test_type && (
            <div className="ml-blur-bg-container" style={{ "--blur-value": "5px", "--blur-spacing-top": "50px" }}>
              <div className="ml-blur-bg"></div>
              <div className="ml-blur-content">
                <div className="requested-type-selector">
                  <p>To see your result, select the type of inboxes you target :</p>

                  <div className="requested-type-options ml-dropdown">
                    <button className="dropdown-toggle button outline blue-outline" onClick={() => this.setTestType("btob")}>
                      <BriefcaseBusiness /> <span>Professional inboxes</span>
                    </button>
                    <button className="dropdown-toggle button outline yellow-outline" onClick={() => this.setTestType("btoc")}>
                      <CircleUser /> <span>Personal inboxes</span>
                    </button>
                    <button className="dropdown-toggle button outline" onClick={() => this.setTestType("all")}>
                      ‍<Globe />
                      <span>All types of inboxes</span>
                    </button>
                  </div>

                  <div>
                    <Tooltip
                      title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
						Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used by your real recipients."
                      placement="bottom"
                    >
                      <p className="note">
                        <i className="fas fa-info-circle"></i> <b>Important</b> : Spam filters work differently between professional and
                        personal inboxes. To avoid biased results, choose the type of inboxes used by the recipients of your real campaigns.
                        You can change that later.
                      </p>
                    </Tooltip>
                    {/*
                      <div className="help-bubble-dropdown">
                        <Tooltip
                          title="It's not relevant to check your deliverability towards personal addresses if you're targeting professionals and vice versa.
								Google and Microsoft understand your emails and may consider suspicious the fact that you're speaking business to a personal inbox which is not supposed to happen. Result ? They put you in spam.
								Conclusion : your true deliverability has to be checked with the inbox type used by your real recipients."
                          placement="bottom"
                        >
                          <div>
                            <i className="fas fa-info-circle"></i>
                          </div>
                        </Tooltip>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="test-results-actions">
            <div className="test-action-title">
              <h2>{testTitles[this.state.currentPage]}</h2>
              {this.state.currentPage === "test" && (
                <Dropdown
                  className="sm fill-bg"
                  options={[
                    { value: "all", label: "All types of inboxes", className: "", icon: Globe },
                    { value: "btob", label: "Professional inboxes", className: "blue-outline", icon: BriefcaseBusiness },
                    { value: "btoc", label: "Personal inboxes", className: "yellow-outline", icon: CircleUser },
                  ]}
                  onSelect={(value) => this.setTestType(value)}
                  selected={this.state.spamTestInboxFilter ? this.state.spamTestInboxFilter : "all"}
                />
              )}
              {this.state.reloadTests && <Loader type="Oval" color="#b7b7b7" height="24" width="24" />}
            </div>

            <div className="text-action-buttons">
              <Button
                variant="outline"
                className="mail-cion"
                disabled={this.state.loading}
                onClick={() => this.setState({ openShowTemplateModal: true })}
              >
                <MailQuestionIcon />
              </Button>
              <Button variant="outline" disabled={this.state.loading} onClick={() => this.setState({ openShowTemplateModal: true })}>
                <span>See my email</span>
              </Button>
              <Button
                variant="primary"
                href={
                  this.state.blockedNextTest
                    ? "https://www.mailreach.co/upgrade/" + this.props.location.search
                    : "https://www.mailreach.co/email-spam-test/" + this.props.location.search
                }
                target="_blank"
                rel="noreferrer"
                as="a"
              >
                <span>Run a new test</span>
                <ArrowRight />
              </Button>
            </div>
          </div>

          <div className="space-y-8">
            {!this.state.currentTest.completed && (
              <Tooltip
                title="We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍."
                placement="bottom"
              >
                <div className="score running pulse">Searching...</div>
              </Tooltip>
            )}

            <div className={"txt text-center " + (this.state.spamTestInboxFilter ? "" : "blured")}>
              {this.state.currentPage === "test" && (
                <>
                  {this.state.currentTest.completed && (
                    <div className="ml-results-info mb-12">
                      <ScoreInfo
                        score={this.state.currentTest.final_score}
                        tooltip="This score is 100% based on where your emails land as it's the best representation of your test email's deliverability. The formula is : [number of emails landed in inbox] / [number of email sent] x 10."
                        description="Unlike other providers, this score is 100% based on where your test emails land."
                      />
                      {adsRd === 1 && (
                        <BannerWithAds
                          variant="light"
                          title="Unlock the full potential of the spam checker"
                          description="Run more tests, see your progress history, optimize your deliverability, improve your open rates and make more sales."
                          href={
                            "https://www.mailreach.co/unlock-spam-test-potential/" +
                            (this.props.location.search
                              ? this.props.location.search + "&utm_medium=warmer_cta&utm_campaign=deliverability-tab"
                              : "?utm_medium=warmer_cta&utm_campaign=deliverability-tab")
                          }
                          cta="Upgrade Now"
                          iconName="Upgrade"
                        />
                      )}
                      {adsRd === 2 && (
                        <BannerWithAds
                          variant="light"
                          title="Your B2B outreach is making it to most inboxes. Warmup can help you optimize further."
                          description="You’re doing great on deliverability, but there’s always room to improve. Fine-tune your sender score and maximize inbox reach with email warming."
                          href={
                            "https://www.mailreach.co/email-warmup/" +
                            (this.props.location.search
                              ? this.props.location.search + "&utm_source=free_spam-test&utm_medium=banner&utm_campaign=near_score"
                              : "?utm_source=free_spam-test&utm_medium=banner&utm_campaign=near_score")
                          }
                          cta="Boost my deliverability"
                          iconName="Target"
                        />
                      )}
                    </div>
                  )}

                  <div className="ml-sub-header  mb-6">
                    <div className="ml-sub-header-title">
                      <h2>Where it landed</h2>
                      {this.state.reloadTests && <Loader type="Oval" color="#b7b7b7" height="20" width="20" />}
                    </div>
                    <div className="ml-sub-header-content">
                      <p>
                        <span className="copy-text">Copy report link</span>
                        <span className="share-text">Share this report</span>
                        <span className="copy-text-mini">Copy link</span>
                      </p>
                      <div className="copy-link" onClick={() => this.copyText(window.location.href.split("?")[0])}>
                        <div className="code">{window.location.href.split("?")[0].slice(0, 50)}</div>
                        <div className="copy">
                          <i className="far fa-copy" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!["technical", "content"].includes(this.state.currentPage) && (
                <Card>
                  {this.state.currentPage === "test" && (
                    <>
                      {this.state.currentTest.results.length === 0 && !this.state.loading && (
                        <div className="empty text-center">
                          <img src="/images/instat_analysis.svg" alt="Empty analysis" />
                          <p>No results at the moment.</p>
                        </div>
                      )}
                      <table className="table table-results">
                        <tbody>
                          {this.state.currentTest.results.map((log, index) => {
                            if (log.hidden) {
                              return null;
                            }
                            return (
                              <tr>
                                <td className="email-info-td">
                                  <EmailInfo
                                    title={log.email}
                                    description={log.desc}
                                    createdAt={moment(this.state.currentTest.created_at).fromNow()}
                                    provider={log.provider}
                                    type={this.getInboxType(log.email)}
                                    onClick={() => this.openHeadersModal(log)}
                                  />
                                </td>
                                <td className="inbox-type">
                                  <div className="ml-table-td-center">
                                    <Badge variant="default" color={this.getInboxType(log.email)}>
                                      {log.provider === "gmail" && <>Google</>}
                                      {log.provider === "outlook" && <>Microsoft</>}
                                      {!["gmail", "outlook"].includes(log.provider) && <>{log.provider}</>}
                                    </Badge>

                                    {this.getInboxTypeLabel(log.email)}
                                  </div>
                                </td>
                                <td className="hour hide-responsive">
                                  <Moment fromNow>{this.state.currentTest.created_at}</Moment>
                                </td>
                                <td>
                                  {!log.received_in && !this.state.currentTest.completed && (
                                    <Badge variant={"pending"}>
                                      <Tooltip
                                        title="Pending means MailReach is still looking for your email in this mailbox. It will be updated soon. If nothing is found after 6 minutes, it's considered as missing and your score is displayed."
                                        placement="bottom"
                                      >
                                        <span className={"pending"}>Pending</span>
                                      </Tooltip>
                                    </Badge>
                                  )}
                                  {!log.received_in && this.state.currentTest.completed && (
                                    <Badge variant={"missing"}>
                                      <Tooltip
                                        title="Missing means MailReach doesn't find your email in this mailbox. It can mean your email has not landed yet OR it can mean the mailbox / provider didn't let you enter. In the latter, that's worst than spam."
                                        placement="bottom"
                                      >
                                        <span className={"missing-badge-color"}>Missing</span>
                                      </Tooltip>
                                    </Badge>
                                  )}
                                  {log.received_in && (
                                    <>
                                      {log.received_in === "INBOX" && (
                                        <Badge variant={log.received_in.toLowerCase()} color={this.getInboxType(log.email)}>
                                          <Tooltip title="Well done 🙌" placement="bottom">
                                            <span>{log.received_in.toLowerCase()}</span>
                                          </Tooltip>
                                        </Badge>
                                      )}
                                      {log.received_in === "SPAM" && this.getInboxType(log.email) === "perso" && (
                                        <Badge variant={log.received_in.toLowerCase()} color={this.getInboxType(log.email)}>
                                          <Tooltip
                                            title="Not good BUT the question is : do you really target personal inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes."
                                            placement="bottom"
                                          >
                                            <span>{log.received_in.toLowerCase()}</span>
                                          </Tooltip>
                                        </Badge>
                                      )}
                                      {log.received_in === "SPAM" && this.getInboxType(log.email) === "pro" && (
                                        <Badge variant={log.received_in.toLowerCase()} color={this.getInboxType(log.email)}>
                                          <Tooltip
                                            title="Not good BUT the question is : do you really target professional inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes."
                                            placement="bottom"
                                          >
                                            <span>{log.received_in.toLowerCase()}</span>
                                          </Tooltip>
                                        </Badge>
                                      )}
                                      {!["INBOX", "SPAM", "MISSING"].includes(log.received_in) && (
                                        <Badge variant={"other"} color={this.getInboxType(log.email)}>
                                          <Tooltip title="Better than spam, but not the best 😐" placement="bottom">
                                            <span>{log.received_in.toLowerCase()}</span>
                                          </Tooltip>
                                        </Badge>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </Card>
              )}
              {this.state.currentPage === "content" && (
                <div className="checkups">
                  {/* <div className="col-md-12 no-padding-right no-padding-left text-left">
                    <div className="card upgrade-spamchecker">
                      <div className="insight-content">
                        <h2 className="pull-left">Sending cold emails? Make sure to land in inbox and sell more 🔥</h2>
                        <p className="pull-left">
                          MailReach's email warmer generates engagement to your emails to raise your reputation and deliverability. It
                          teaches Google & Microsoft to send your emails to the inbox. Get higher open, click, reply rates and make more
                          sales.
                        </p>
                        <div className="col-md-12">
                          <a
                            className="btn btn-primary green-bck"
                            href={
                              "https://www.mailreach.co/email-warmup/" +
                              (this.props.location.search
                                ? this.props.location.search + "&utm_medium=warmer_cta&utm_campaign=content-tab"
                                : "?utm_medium=warmer_cta&utm_campaign=content-tab")
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Boost my deliverability
                          </a>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div> */}

                  <WordsAnalyzer
                    title={false}
                    currentTest={this.state.currentTest}
                    contentSpamwordsResponse={this.state.currentTest.analysis_results}
                  />
                  <div className="clearfix"></div>
                </div>
              )}
              {this.state.currentPage === "technical" && (
                <div className="checkups">
                  {/* <div className="col-md-12 no-padding-right no-padding-left text-left">
                    <div className="card upgrade-spamchecker">
                      <div className="insight-content">
                        <h2 className="pull-left">Sending cold emails? Maximize your inbox rate and get more sales 🔥</h2>
                        <p className="pull-left">
                          MailReach's email warmer generates engagement to your emails to raise your reputation and deliverability. It
                          teaches Google & Microsoft to send your emails to the inbox. Get higher open, click, reply rates and make more
                          sales.
                        </p>
                        <div className="col-md-12">
                          <a
                            className="btn btn-primary green-bck"
                            href={
                              "https://www.mailreach.co/email-warmup/" +
                              (this.props.location.search
                                ? this.props.location.search + "&utm_medium=warmer_cta&utm_campaign=setup-tab"
                                : "?utm_medium=warmer_cta&utm_campaign=setup-tab")
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Boost my deliverability
                          </a>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div> */}

                  <CheckupDomain domain={this.state.currentTest.domain} currentTest={this.state.currentTest} spamTest={true} />
                  <div className="clearfix"></div>
                </div>
              )}
            </div>
            <CTAWithImage
              title="Are you a B2B business sending cold emails?"
              image="/images/cta-image.png"
              lottie={spamFilterLottie}
              href={`https://www.mailreach.co/email-warmup/utm_source=free_spam-test&utm_medium=banner&utm_campaign=${this.state.currentTabValue}`}
            >
              <p>Even with great content and proper setup, your emails can still end up in spam.</p>
              <p>What you need is a strong sender reputation.</p>
              <p className="highlight">That's where Mailreach comes in.</p>
              <p>
                We train spam filters to recognize you as a trusted sender by positively engaging with real inboxes. Making your
                deliverability unbeatable.
              </p>
            </CTAWithImage>

            <div className="ml-stats-review">
              <Stats />
              <Testimonials />
            </div>
            <CTABanner
              title={`<em>Why wait?</em> Sign up now to see the results for yourself!`}
              href={`https://www.mailreach.co/email-warmup/?utm_source=free_spam-test&utm_medium=banner&utm_campaign=${this.state.currentTabValue}`}
            />
          </div>
        </div>
        {this.state.currentTest.first_message_content && (
          <Modal
            className="spam-results-modal message-design"
            show={this.state.openShowTemplateModal}
            onHide={() => this.setState({ openShowTemplateModal: false })}
          >
            <Modal.Body>
              <>
                <h1 className="text-center">{this.state.currentTest.first_message_content.subject}</h1>
                <div className="from">
                  <b>{this.state.currentTest.first_message_content.from_name}</b>{" "}
                  {"<" + this.state.currentTest.first_message_content.from_email + ">"}
                  <ul className="format-selector pull-right">
                    <li
                      className={"btn btn-secondary" + (this.state.messageContentPreview === "content_html" ? " selected" : "")}
                      onClick={() => this.setState({ messageContentPreview: "content_html" })}
                    >
                      HTML
                    </li>
                    <li
                      className={"btn btn-secondary" + (this.state.messageContentPreview === "content_text" ? " selected" : "")}
                      onClick={() => this.setState({ messageContentPreview: "content_text" })}
                    >
                      TEXT
                    </li>
                  </ul>
                </div>
                <div className="txt">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(this.state.currentTest.first_message_content[this.state.messageContentPreview]),
                    }}
                  />
                </div>
                <div className="footer text-center">
                  <button type="button" className="btn btn-secondary" onClick={() => this.setState({ openShowTemplateModal: false })}>
                    Close
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>
        )}

        <Modal
          className="spam-results-modal message-design"
          show={this.state.openShowHeadersModal}
          onHide={() => this.setState({ openShowHeadersModal: false })}
        >
          <Modal.Body>
            <>
              <h1 className="text-center">Message Raw Headers</h1>
              <div className="headers">
                <table>
                  <tbody>
                    {this.state.headersToShow.map((header, index) => {
                      return (
                        <tr>
                          <td>{header.name} :</td>
                          <td className="break-word">{header.unparsed_value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="footer text-center">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({ openShowHeadersModal: false })}>
                  Close
                </button>
              </div>
            </>
          </Modal.Body>
        </Modal>

        <footer className="more-info">
          <a href="https://www.mailreach.co/?utm_medium=footer-links&utm_campaign=mailreach-cta" target="_blank" rel="noreferrer">
            Maximize your cold email deliverability with MailReach
          </a>
          <a
            href="https://www.mailreach.co/blog/why-are-my-emails-going-to-spam?utm_medium=footer-links&utm_campaign=why-emails-going-to-spam"
            target="_blank"
            rel="noreferrer"
          >
            Why are my emails going to spam?
          </a>
          <a
            href="https://www.mailreach.co/blog/how-to-prevent-emails-from-going-to-spam?utm_medium=footer-links&utm_campaign=deliverability-guide"
            target="_blank"
            rel="noreferrer"
          >
            The Ultimate Deliverability Guide
          </a>
        </footer>

        {this.state.showPopup && (
          <>
            <div className="overlay"></div>
            <div className="pop-warmer text-center">
              <i onClick={this.closePopup} className="fas fa-times"></i>
              <div className="text-center">
                <img src="/images/popup_warmer.svg" alt="Email Warmer " />
              </div>
              <h1>We're 80% sure our email warmer can help you.</h1>
              <p>As your content and sending setup look OK, you may land in spam because of a damaged sender reputation.</p>

              <div className="text-center">
                <a className="btn btn-primary green-bck" href="https://www.mailreach.co/?utm_medium=smart-popup-warmer">
                  Raise my reputation
                </a>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default AppTests;
