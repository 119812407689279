import React from "react";
import "./button.scss";

const Button = ({ children, variant = "", className = "", as = "button", ...props }) => {
  let defaultProps = {
    type: "button",
  };
  const defaultAnchorProps = {
    href: "#",
    role: "button",
    tabIndex: 0,
    rel: "noreferrer",
  };
  if (as === "a") {
    defaultProps = { ...defaultProps, ...defaultAnchorProps };
  }
  props = { ...defaultProps, ...props };
  const Tag = as;
  return (
    <Tag className={`button ${variant} ${className}`.trim()} {...props}>
      {children}
    </Tag>
  );
};

export default Button;
