import "./icon.scss";
import { IconsList } from "./IconsList";
const DefaultIcon = IconsList.Target;

export const Icon = ({ children, className = "", size = "md", name = "", ...props }) => {
  const NamedIcon = IconsList[name];

  return (
    <div className={`ml-icon-box ${className} ${size}`} {...props}>
      {NamedIcon ? <NamedIcon /> : children ? children : <DefaultIcon />}
    </div>
  );
};
