import { DotIcon } from "lucide-react";
import { ClientIcon } from "./Icon";
import "./email.scss";

export const EmailInfo = ({ title = "", description = "", provider = "none", createdAt = "", type = "", className = "", ...props }) => {
  return (
    <div className={`ml-email-info ${className}`.trim()} {...props}>
      <ClientIcon provider={provider} className="ml-email-info-icon" />
      <div className="ml-email-info-content">
        <p>{title}</p>
        <div className="ml-email-more-info">
          <span className={"ml-email-inbox-type " + type}>{type === "perso" ? "Personal" : "Professional"}</span>
          <DotIcon className="ml-email-info-dot" />
          <span>
            {provider === "gmail" && <>Google</>}
            {provider === "outlook" && <>Microsoft</>}
            {!["gmail", "outlook"].includes(provider) && <>{provider}</>}
          </span>
          {typeof createdAt === "string" && createdAt && (
            <>
              <DotIcon className="ml-email-info-dot" />
              <span>{createdAt}</span>
            </>
          )}
        </div>
        <span>{description}</span>
      </div>
    </div>
  );
};
