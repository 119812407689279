import { OfferBanner } from "../banners/Banner";
import Button from "../button/Button";
import "./navbar.scss";

export const Navbar = ({ showOfferBanner = false, onBannerClose = null, ...props }) => {
  return (
    <div className="ml-navbar" {...props}>
      {showOfferBanner && <OfferBanner onBannerClose={onBannerClose} />}
      <div className="container">
        <div className="ml-navbar-header">
          <a href="https://www.mailreach.co/">
            <img src="/images/logo.png" alt="Mail Reach" />
          </a>
          <Button
            variant="secondary"
            as="a"
            style={{
              "--font-weight": "700",
              "letter-spacing": "0.5px",
            }}
            href="https://www.mailreach.co/email-warmup/?utm_medium=warmer_cta&utm_campaign=header-nav"
            target="_blank"
          >
            Start Improving Deliverability
          </Button>
        </div>
      </div>
    </div>
  );
};
