import React, { useEffect } from "react";
import Button from "../button/Button";
import "./tabs.scss";

const Tabs = ({ className = "", options = [], onSelect = null, selected = "", ...props }) => {
  const [value, setValue] = React.useState(null);

  const buttonProps = {
    role: "tab",
    defaultVariant: "",
    activeVariant: "primary",
  };

  const tabNavProps = {
    role: "tablist",
    className: "ml-tabs",
  };

  const tabItemProps = {
    role: "presentation",
    className: "ml-tab",
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === selected);
    setValue(selectedOption);
  }, [selected, options, value]);

  if (!options.length) throw new Error("Tabs: options is required");
  return (
    <ul {...tabNavProps}>
      {options.map((option, index) => (
        <li key={index} {...tabItemProps}>
          <Button
            {...buttonProps}
            onClick={() => onSelect(option.value, option)}
            variant={value?.value === option.value ? buttonProps.activeVariant : buttonProps.defaultVariant}
            aria-selected={value?.value === option.value}
          >
            {["object", "function"].includes(typeof option.icon) ? <option.icon /> : option.icon}
            <span>{option.label}</span>
            {["object", "function"].includes(typeof option.suffix) && <option.suffix />}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default Tabs;

export const TabDot = () => {
  return <span className="ml-tab-dot" />;
};
