import { Tooltip } from "@material-ui/core";
import { Button, IconsList } from "../../../components/core";
import { ArrowUpRight } from "lucide-react";
import { sanitize } from "dompurify";

export const AnalyzerInfo = ({
  type = "success",
  button = null,
  title = "",
  isLoading = false,
  tooltip = null,
  className = "",
  onAction = null,
  iconTooltip = "",
}) => {
  const GetIcon = () => {
    let Icon = null;
    switch (type) {
      case "success":
        Icon = <IconsList.CheckCircle />;
        break;
      case "warning":
        Icon = <IconsList.CircleDot />;
        break;
      case "error":
        Icon = <IconsList.CircleX />;
        break;
      default:
        Icon = <IconsList.CircleDot />;
    }
    if (typeof iconTooltip === "string") {
      return (
        <Tooltip title={iconTooltip} placement="left">
          <i>{Icon}</i>
        </Tooltip>
      );
    }
    return Icon;
  };

  const TooltipInfo = ({ title, placement = "bottom" }) => {
    return (
      <Tooltip title={title} placement={placement}>
        <div className="ml-analyzer-info-tooltip">
          <i class="fas fa-info-circle"></i>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={`ml-analyzer-info ${className}`}>
      <div className="analyzer-info-content">
        <GetIcon />
        <p dangerouslySetInnerHTML={{ __html: sanitize(title) }}></p>
        {tooltip && <TooltipInfo title={tooltip} />}
      </div>
      {button && (
        <Button variant="link" disabled={isLoading} onClick={() => (onAction ? onAction() : null)}>
          <span>{button}</span>
          <ArrowUpRight />
        </Button>
      )}
    </div>
  );
};
