import React, { Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import "./App.css";

// import Sidebar from "./components/sidebar/sidebar.js";

import AppTests from "./pages/tests/test.js";
import AppSpfTests from "./pages/tests/spf-test.js";
import AppDkimTests from "./pages/tests/dkim-test.js";
import AppSummary from "./pages/summary/summary.js";
import AppRedirect from "./pages/redirect/redirect.js";
import { Navbar } from "./components/core/navbar/Navbar.js";
import { ToastContainer } from "react-toastify";

function App() {
  const [showOfferBanner, setShowOfferBanner] = React.useState(false);

  React.useEffect(() => {
    if (window?.location?.href.includes("/tests/")) {
      // Update banner logic here
      setShowOfferBanner(true);
    }
  }, []);

  React.useEffect(() => {
    if (showOfferBanner) {
      document.querySelector(".app-wrapper")?.classList.add("with-offer-banner");
    } else {
      document.querySelector(".app-wrapper")?.classList.remove("with-offer-banner");
    }
  }, [showOfferBanner]);

  return (
    <div className="app app-wrapper">
      <Navbar showOfferBanner={showOfferBanner} onBannerClose={() => setShowOfferBanner(false)} />
      <div className="app side">
        <div className="app home spamtest-results spam-results-modal template-home">
          <Suspense fallback="loading">
            <Router>
              <Route path="/rd" component={AppRedirect} />
              <Route path="/tests/:test_id" component={AppTests} />
              <Route path="/spf/:test_id" component={AppSpfTests} />
              <Route path="/dkim/:test_id" component={AppDkimTests} />
              <Route path="/summary/:email" component={AppSummary} />
              <Route exact path="/">
                <Redirect to="/rd" />
              </Route>
            </Router>
          </Suspense>
        </div>
      </div>
      <ToastContainer
        style={{
          marginTop: showOfferBanner ? "130px" : "60px",
          zIndex: 1000,
        }}
      />
    </div>
  );
}

export default App;
