import { CheckIcon, CircleDotIcon, CopyIcon, CrownIcon, GiftIcon } from "lucide-react";

const targetIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M17.5 12C17.5 15.0376 15.0376 17.5 12 17.5C8.96243 17.5 6.5 15.0376 6.5 12C6.5 8.96243 8.96243 6.5 12 6.5"
        stroke="currentColor"
        stroke-width="2"
      />
      <path d="M12 11.9984L15.85 8.14844" stroke="currentColor" stroke-width="2" />
      <path
        d="M15.3008 5.40156L15.8508 8.15156L18.6008 8.70156L21.9008 5.40156L19.1508 4.85156L18.6008 2.10156L15.3008 5.40156Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  );
};
const startIcon = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0066 18.26L4.95307 22.2082L6.52842 14.2799L0.59375 8.7918L8.6209 7.84006L12.0066 0.5L15.3923 7.84006L23.4194 8.7918L17.4848 14.2799L19.0601 22.2082L12.0066 18.26Z"
        fill="#E8B500"
      />
    </svg>
  );
};

const analysisIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7401 7.49609L9.84575 10.3904L8.08286 8.62479L5.73047 10.9772" stroke="currentColor" stroke-width="1.67" />
      <path
        d="M1.59766 9.23711C1.59766 11.2627 2.40232 13.2053 3.83463 14.6377C5.26694 16.0699 7.20957 16.8746 9.23516 16.8746C11.2608 16.8746 13.2034 16.0699 14.6357 14.6377C16.068 13.2053 16.8727 11.2627 16.8727 9.23711C16.8727 7.21152 16.068 5.26889 14.6357 3.83658C13.2034 2.40427 11.2608 1.59961 9.23516 1.59961C7.20957 1.59961 5.26694 2.40427 3.83463 3.83658C2.40232 5.26889 1.59766 7.21152 1.59766 9.23711Z"
        stroke="currentColor"
        stroke-width="1.67"
      />
      <path d="M18.0815 18.0833L14.6406 14.6425" stroke="currentColor" stroke-width="1.67" />
    </svg>
  );
};

const TooltipIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C5.87827 0 3.84344 0.842853 2.34315 2.34315C0.842853 3.84344 0 5.87827 0 8C0 10.1217 0.842853 12.1565 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1565 15.1571 13.6569 13.6569C15.1571 12.1565 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1565 0.842853 10.1217 0 8 0ZM8 4.66667C7.07953 4.66667 6.33333 5.41286 6.33333 6.33333V6.66667H5V6.33333C5 4.67648 6.34315 3.33333 8 3.33333C9.65687 3.33333 11 4.67648 11 6.33333V6.97327L10.7672 7.17287L8.66667 8.97327V10.3333H7.33333V8.36007L7.56613 8.16047L9.66667 6.36004V6.33333C9.66667 5.41286 8.92047 4.66667 8 4.66667ZM7.33333 11.3333V12.6667H8.66667V11.3333H7.33333Z"
        fill="#11103C"
      />
    </svg>
  );
};

const CheckCircle = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2665_3478)">
        <path
          d="M1.91211 11.9983C1.91211 14.6728 2.97456 17.2378 4.86572 19.129C6.75688 21.0201 9.32185 22.0826 11.9964 22.0826C14.6709 22.0826 17.2359 21.0201 19.127 19.129C21.0182 17.2378 22.0806 14.6728 22.0806 11.9983C22.0806 9.3238 21.0182 6.75884 19.127 4.86767C17.2359 2.97651 14.6709 1.91406 11.9964 1.91406C9.32185 1.91406 6.75688 2.97651 4.86572 4.86767C2.97456 6.75884 1.91211 9.3238 1.91211 11.9983Z"
          stroke="#27B970"
          stroke-width="2"
        />
        <path d="M7.64062 11.9951L10.8493 15.2038L16.3497 8.32812" stroke="#27B970" stroke-width="2" />
      </g>
      <defs>
        <clipPath id="clip0_2665_3478">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CircleX = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2665_3779)">
        <path d="M17.002 6.99805L7 17" stroke="#D83B22" stroke-width="2" />
        <path d="M7 6.99805L17.002 17" stroke="#D83B22" stroke-width="2" />
        <path
          d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12Z"
          stroke="#D83B22"
          stroke-width="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2665_3779">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const InfoQuestionMark = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C5.87827 0 3.84344 0.842853 2.34315 2.34315C0.842853 3.84344 0 5.87827 0 8C0 10.1217 0.842853 12.1565 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1565 15.1571 13.6569 13.6569C15.1571 12.1565 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1565 0.842853 10.1217 0 8 0ZM8 4.66667C7.07953 4.66667 6.33333 5.41286 6.33333 6.33333V6.66667H5V6.33333C5 4.67648 6.34315 3.33333 8 3.33333C9.65687 3.33333 11 4.67648 11 6.33333V6.97327L10.7672 7.17287L8.66667 8.97327V10.3333H7.33333V8.36007L7.56613 8.16047L9.66667 6.36004V6.33333C9.66667 5.41286 8.92047 4.66667 8 4.66667ZM7.33333 11.3333V12.6667H8.66667V11.3333H7.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};

const SpinnerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      class="animate-spin"
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  );
};

export const IconsList = {
  Gift: GiftIcon,
  Target: targetIcon,
  Check: CheckIcon,
  CheckCircle,
  CircleX,
  CircleDot: CircleDotIcon,
  Copy: CopyIcon,
  Analysis: analysisIcon,
  Star: startIcon,
  TooltipIcon,
  Upgrade: CrownIcon,
  InfoQuestionMark,
  Spinner: SpinnerIcon,
};
